
import { defineComponent, onMounted, computed, reactive, onBeforeMount, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import router from "@/router";

export default defineComponent({
  name: "staff-list",
  components: {
  },
  setup() {
    const staffs = reactive<any>([]);

    const getStaffs = async () => {
        await new Promise((resolve, reject) => {   
          ApiService.setHeader();         
            ApiService.get("staff")
                .then(({ data }) => {
                    Object.assign(staffs,data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(new Error ('getStaffs error => '+response.data.errors));
                });
        });
    }

    const pageSize = ref(20);
    let page = reactive({pageValue : 1});

    const pagedTableDate = computed(()=>{
      if(staffs){
          return getFilteredStaffList().slice(pageSize.value * page.pageValue - pageSize.value, pageSize.value * page.pageValue)
      }
      else{
          return [];
      }
     })

    const getFilteredStaffList = () =>{
      return staffs.filter(data => !search 
                                  || (data.first_name+' '+data.last_name).toLowerCase().includes(search.searchValue.toLowerCase())
                                  || data.phone.toLowerCase().includes(search.searchValue.toLowerCase())
                                  || data.email.toLowerCase().includes(search.searchValue.toLowerCase())
                                  || data.type.toLowerCase().includes(search.searchValue.toLowerCase()));
    }

    const pageSizes = computed(()=>{
      let sizes = [20];
      let curr_length = getFilteredStaffList().length;

      if(curr_length > 20){
        sizes.push(50);
        if(curr_length >50){
          let i = 0;
          while(i<curr_length){
            i+=100;
            sizes.push(i);
          }
        }
      }

      return sizes;
    })

    const search = reactive({searchValue : ''});

    const setPage = (val) => {
      page.pageValue = val;
    }
    
    const handleSizeChange = (val: number) => {
      pageSize.value = val; 
    }

    const filterActive = (value, row) => {
      return row.is_active === value
    }

    const selectStaff= (row) =>{
      router.push({ name: "staff-detail", params: { id: row.id } });
    }

    const openCreateStaff= () =>{
      router.push({ name: "staff-detail", params: { id: 0 } });
    }

    onBeforeMount(async() => {
      await getStaffs();
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("Faculty/Staff", ["Management"]);
    });

    return {
      handleSizeChange,
      pagedTableDate,
      pageSize,
      search,
      filterActive,
      selectStaff,
      staffs,
      pageSizes,
      getFilteredStaffList,
      openCreateStaff,
      setPage
    };
  },
});
